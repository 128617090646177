@import 'src/styles/mixins';

.SecretDefinitionListing {
  &__Filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.4rem;
  }

  &__TypeFilter {
    width: 21.8rem;
  }

  &__Grid {
    width: 100%;
  }

  &__GridRow {
    min-height: 8.8rem;
  }

  &__GridCell {
    &--name {
      flex-grow: 2;

      > button {
        @include focus-outline-hidden;
        justify-content: flex-start;
        text-align: left;
      }
    }

    &--description {
      flex-grow: 4;
      padding-right: 6rem;
    }

    &--actions {
      flex-grow: 0;
      justify-content: flex-end;
    }
  }
}

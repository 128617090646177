.Modal {
  &__Heading {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
  }

  // TO-DO this size needs to be supported by the MCL Typography component
  &__Title {
    font-size: 36px;
    line-height: 1em;
  }

  &__Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 32px auto 0;

    &--single-button {
      justify-content: flex-end;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.DatePicker {
  input {
    &:focus-visible {
      outline: none;
    }
  }

  [class~='react-date-picker__wrapper'] {
    width: 189px;
    height: 45px;
  }

  [class~='react-date-picker__inputGroup'] {
    padding: 14px;
  }

  [class~='react-date-picker__inputGroup__input']:invalid {
    background: transparent;
  }

  [class~='react-date-picker__calendar-button'] {
    padding-right: 14px;
  }

  [class~='react-calendar'] {
    width: 289px;
    border: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-family: inherit;
  }

  [class~='react-calendar__month-view__weekdays'] {
    padding-bottom: 4.5px;
  }

  [class~='react-calendar__month-view__weekdays__weekday'] {
    font-family: inherit;

    > abbr {
      text-decoration: none;
    }
  }

  [class~='react-calendar__navigation'] {
    height: auto;
    margin-bottom: 1rem;

    > * {
      padding-top: 10px;
      padding-bottom: 1rem;
      font-family: inherit;
      font-weight: 200;
    }
  }

  [class~='react-calendar__navigation__prev2-button'] {
    display: none;
  }

  [class~='react-calendar__navigation__next2-button'] {
    display: none;
  }

  [class~='react-calendar__navigation__next-button'] {
    background: url('/assets/arrowRight.svg') no-repeat;
    background-position: center;
    text-indent: -9999px; // react-date-picker uses a text of ">" to for the button but we want to hide it and just use the background above
  }

  [class~='react-calendar__navigation__prev-button'] {
    background: url('/assets/arrowLeft.svg') no-repeat;
    background-position: center;
    text-indent: -9999px; // react-date-picker uses a text of ">" to for the button but we want to hide it and just use the background above
  }

  [class~='react-calendar__navigation__label'] {
    &:disabled {
      background-color: unset;
      color: unset;
    }
  }

  [class~='react-calendar__viewContainer'] {
    padding-bottom: 7.5px;
  }

  [class~='react-calendar__month-view__days__day--weekend'] {
    color: unset;

    &:disabled {
      color: $windy;
    }
  }

  [class~='react-calendar__month-view__days__day--neighboringMonth'] {
    color: $black;
  }

  [class~='react-calendar__tile'] {
    position: relative;
    z-index: 1;
    padding: 9.5px;
    background-color: transparent;
    font-family: inherit;

    &::before {
      content: ('');
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      transform: translate(35%, 22%);
      border-radius: 100%;
    }

    &:hover {
      background-color: transparent;

      &::before {
        background-color: $misty;
      }
    }

    &:disabled {
      color: $windy;

      &::before {
        display: none;
      }
    }
  }

  [class~='react-calendar__tile--active'] {
    color: $ocean-blue;

    &::after {
      content: ('');
      position: absolute;
      bottom: 2px;
      left: 50%;
      width: 4px;
      height: 4px;
      transform: translate(-50%, 0);
      background-color: $ocean-blue;
    }
  }
}

.ProviderSelection {
  &__Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
  }

  &__TextContainer {
    margin-top: 10px;
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.AppFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 85px;
  border-top: 1px solid $cloudy;

  &__Links {
    display: flex;
  }
}

.AppLayout {
  &__Page {
    // should be 120px but sections on pages add the extra padding of 8px or 16px based on screen size
    margin-bottom: 104px;
    @media only screen and (max-width: 1023px) {
      margin-bottom: 112px;
    }
  }
}

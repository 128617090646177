@import '@matillion/component-library/dist/assets/styles/colours';

.HostTypeCard {
  @mixin selected-state {
    // Decreasing padding by 1px to give space to border so the component doesn't move
    padding: calc(4rem - 1px);
    border-width: 2px;
    border-color: $plasma-green;

    .HostTypeCard__RhsBannerText {
      padding-top: 7px;
      padding-left: 15px;
    }

    .HostTypeCard__RadioBtn {
      padding-top: 15px;
      padding-right: 15px;
    }

    .HostTypeCard__Minutes {
      padding: 0 15.5px;
      border-width: 1.5px;
      border-color: $black;
      color: $black;
    }
  }

  &__Container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 466px; // based on design
    height: 274px; // based on design
    padding: 4rem;
    overflow: hidden;
    border: 1px solid #c4c8cc;
    border-radius: 16px;
    background-color: $grey-03;
    cursor: pointer;

    ul {
      margin-top: 15px;
      margin-left: 30px;
    }

    &:hover {
      @include selected-state;

      .HostTypeCard__RadioBtn {
        > span {
          border-color: $black !important;
        }
      }
    }

    &--selected {
      @include selected-state;

      .HostTypeCard__RadioBtn {
        > span {
          border-color: transparent !important;
        }
      }

      &:hover {
        .HostTypeCard__RadioBtn {
          > span {
            border-color: transparent !important;
          }
        }
      }
    }
  }

  &__TopBanner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-between;
    width: 100%;
  }

  &__Content {
    width: 100%;
    text-align: start;
  }

  &__RhsBannerText {
    padding: 8px 16px;
    border-radius: 0 0 15px;
    background-color: $gale;
    color: $electric-green;
  }

  &__SetUp {
    height: min-content;
    padding: 5px 32px;
    border-bottom-right-radius: 10px;
    background-color: $gale;
    color: $plasma-green;
  }

  &__RadioBtn {
    margin-left: auto;
    padding: 16px 16px 0 0;

    > span {
      border-color: $windy !important;
    }
  }

  &__LogosContainer {
    margin-top: 13px;
    margin-bottom: 32px;
  }

  &__LogosPill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 296px;
    margin: auto;
    padding: 21px 46px;
    border: 1px dashed $windy;
    border-radius: 39px;

    > :first-child {
      width: 50px;
      height: 50px;
    }

    > :last-child {
      width: 50px;
      height: 50px;
    }
  }

  &__Icon {
    margin: 0;

    &--pos-center {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__TitleContainer {
    margin-bottom: 16px;
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--rhs {
      justify-content: flex-end;
    }
  }

  &__Minutes {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 16px;
    border: 1px solid $windy;
    border-radius: 5px;
    color: $windy;
  }

  &__RhsFooter {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 8px;
    }
  }
}

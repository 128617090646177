@import '@matillion/component-library/dist/assets/styles/colours';

.ProviderCard {
  width: 100%;
  min-width: 180px;
  height: 100%;
  max-height: 180px;
  border: 1px solid $windy;
  border-radius: 16px;
  background: none;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      border-color: $plasma-green;
    }
  }

  &--selected {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  }

  &__Container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px 6px;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__Radio {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__LogoContainer {
    width: 50px;
    height: 50px;
  }

  &__Logo {
    width: 100%;
    height: 100%;
  }
}

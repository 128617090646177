.DeleteProjectContent {
  &__Paragraph {
    display: block;
    width: 100%;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.StatusHeader {
  &__Icon {
    display: inline-block;
    margin-right: 16px;
    transition: all 0.2s ease;
    opacity: 1;
    vertical-align: middle;

    &--Loading {
      width: 4.4rem;
      color: $electric-green;
    }

    &--Disabled {
      opacity: 0.5;
      color: $windy;
    }

    &--AnimateOut {
      opacity: 0;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/spacing';

$outline: #c4c8cc;

.WarehouseProvisioningCard {
  @mixin selected-state {
    border-color: $plasma-green;

    .WarehouseProvisioningCard__Minutes {
      border-color: $black;
      color: $black;
    }
  }

  &__Container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 20px);
    min-height: 230px;
    padding: 4rem 2rem 1.3rem;
    overflow: hidden;
    border: 1px solid $outline;
    border-radius: 8px;
    background-color: $grey-03;

    @include respond-to('large') {
      width: 250px;
    }

    ul {
      margin-left: 30px;
    }

    &:not(&--unavailable) {
      cursor: pointer;

      &:hover {
        @include selected-state;

        .WarehouseProvisioningCard__RadioBtn {
          > span {
            border-color: $black !important;
          }
        }
      }
    }

    &--selected {
      @include selected-state;

      .WarehouseProvisioningCard__RadioBtn {
        > span {
          border-color: transparent !important;
        }
      }

      &:hover {
        .WarehouseProvisioningCard__RadioBtn {
          > span {
            border-color: transparent !important;
          }
        }
      }
    }

    &--unavailable {
      .WarehouseProvisioningCard__LogosContainer > svg,
      .WarehouseProvisioningCard__PoweredBy > svg {
        filter: grayscale(1);
      }

      .WarehouseProvisioningCard__TitleContainer,
      .WarehouseProvisioningCard__SupportText,
      .WarehouseProvisioningCard__PoweredBy {
        color: $hurricane;
      }
    }
  }

  &__TopBanner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-between;
    width: 100%;
  }

  &__Content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: $black;
    text-align: left;
  }

  &__LhsBannerText {
    padding: 5px 16px;
    border-radius: 0 0 15px;
    background-color: $soft-positive;
    color: $positive;

    &--unavailable {
      background-color: $cloudy;
      color: $black;
    }
  }

  &__RadioBtn {
    margin-left: auto;
    padding: 6px 6px 0 0;

    > span {
      border-color: $windy !important;
    }
  }

  &__LogosContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    text-align: center;

    > svg {
      width: 100%;
    }
  }

  &__TitleContainer {
    text-align: center;
  }

  &__SupportText {
    min-height: 100px;
    margin-top: 20px;
  }

  &__Footer {
    position: relative;
    width: 100%;
    height: 30px;
  }

  &__FooterFloat {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__Minutes {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 6px;
    border: 1px solid $windy;
    border-radius: 5px;
    background-color: $white;
    color: $windy;
  }

  &__PoweredBy {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 8px;
    }
  }
}

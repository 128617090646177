@import '@matillion/component-library/dist/assets/styles/colours';

.ReturnLink {
  display: flex;
  position: relative;
  align-items: center;
  max-width: fit-content;
  border: 0;
  outline: none;
  background: none;
  color: $plasma-green;
  cursor: pointer;

  &:not([disabled]) {
    &:focus {
      outline: none;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border: 2px solid $black;
        border-radius: 31px;
      }
    }
  }

  &__Text {
    margin-left: 0.5rem;
  }
}

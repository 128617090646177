@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';
@import 'src/styles/mixins';

.BranchesListing {
  &--content-link {
    @include brand-font;
    @include focus-outline-hidden;
    justify-content: flex-start;
    padding: 0;
    border-radius: 0;
    color: $plasma-green;
    text-align: left;

    &:hover {
      color: $electric-green;
    }
  }

  &--content-large {
    font-size: 2rem;
  }

  &__Header {
    max-width: 800px;
  }

  &__EmptyInfo {
    margin-top: 2.4rem;
  }

  &__Grid {
    width: 100%;
  }

  &__GridCell {
    flex: 1 0;

    &--action {
      flex: 0;
      justify-content: flex-end;
      width: 5rem;
      min-width: 5rem;
    }
  }

  &__GridRow {
    min-height: 8.8rem;
  }

  &__Tooltip {
    max-width: 20rem;
  }
}

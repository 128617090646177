@import 'src/styles/mixins';
@import '@matillion/component-library/dist/assets/styles/colours';

.EllipsesActionItem {
  max-width: 25rem;

  &__Button {
    @include focus-outline-hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $black;
    text-align: left;

    &:hover:not([disabled]) {
      color: $black;
    }

    &--danger:not([disabled]) {
      &:hover {
        color: $fire-red;
      }
    }
  }

  &__Icons {
    max-width: 3.2rem;
    margin-right: 0.6rem;
    padding: 0;
  }
}

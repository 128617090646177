.NoResourcesAvailable {
  $root: &;
  flex: 1 1;
  max-width: 600px;
  margin: auto;
  margin-top: 9rem;
  text-align: center;

  &__Text {
    max-width: 592px;
  }

  &__Button {
    margin: 5.6rem auto auto;

    span {
      font-size: 1.4rem;
    }
  }

  &--Small {
    margin-top: 80px;

    svg {
      width: 72px;
      height: 72px;
    }

    #{$root}__Text {
      margin-bottom: 32px;
    }
  }
}

.EnvironmentsListing {
  &__Header {
    max-width: 800px;
  }

  &__EmptyInfo {
    margin-top: 2.4rem;
  }

  &__Grid {
    width: 100%;
  }

  &__GridCell {
    flex: 1 0;
  }

  &__GridRow {
    min-height: 8.8rem;
  }

  &__ActionCell {
    flex: 0;
    min-width: 5rem;
  }
}

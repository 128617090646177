.ProjectExplorerModal {
  &__Container {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__Content {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

/* stylelint-disable */
@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';
@import 'src/styles/mixins';

.ProjectsListing {
  width: 100%;
  margin-top: 24px;

  &--content-link {
    @include focus-outline-hidden;
    @include brand-font;
    justify-content: flex-start;
    text-align: left;
    color: $plasma-green;
    border-radius: 0;
    padding: 0;

    &:hover {
      color: $electric-green;
    }
  }

  &--content-large {
    font-size: 2rem;
  }

  &--content-ellipsis {
    @include text-ellipsis;
  }

  &__Grid {
    width: 100%;
  }

  &__GridCell {
    flex: 1 0;

    &--stretch-col {
      flex: 1 1 calc(100% - 850px);
    }

    &--action {
      flex: 0;
      justify-content: flex-end;
      width: 5rem;
      min-width: 5rem;
    }
  }

  &__GridRow {
    min-height: 8.8rem;
  }

  &__Profile {
    text-transform: capitalize;

    img {
      padding: 0.6rem;
      background-color: $misty;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.Form {
  flex: 1;
  text-align: center;

  &--ReadOnly {
    input {
      opacity: 1;
      color: $black;
      cursor: not-allowed;
    }
  }

  &__SpacingStyles {
    margin-top: 4rem;
  }

  &__RadioButtonSpacing {
    margin-top: 2rem;
  }

  &__FixedHeight {
    height: 9rem;
  }

  &__GhostField {
    height: 1rem;
  }

  &__Container {
    text-align: left;
  }

  &__OuterWrap {
    width: 100%;
    margin: auto;

    &--space-top {
      padding-top: 1.6rem;
    }
  }

  &__InnerWrap {
    width: 100%;
    max-width: 624px;
    margin: auto;

    &--space-top {
      padding-top: 1.6rem;
    }
  }

  &__SupportText {
    margin: 1.6rem auto auto;
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 624px;
    margin: 32px auto 0;

    &--expanded-container {
      max-width: calc(476px * 2);
    }
  }

  &__Button {
    &:last-child {
      margin-left: auto;
    }

    & + & {
      margin-left: 1rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
}

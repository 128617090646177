@import '@matillion/component-library/dist/assets/styles/colours';
@import 'src/styles/mixins';

.MorePopover {
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  &__ContextMenuEllipsis--show {
    background: $misty;
    color: $plasma-green;
  }

  &__ContextMenuEllipsis--hide {
    color: $black;

    &:hover,
    &:focus {
      background: $misty;
    }
  }

  &__ContextMenuEllipsis {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__MoreButton {
    min-width: 3.2rem;
    height: 3.2rem;
  }
}

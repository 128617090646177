.EditUser {
  &__Form {
    margin-top: 2.4rem;
    padding: 0;

    &__Field {
      margin-bottom: 4rem;
    }
  }
}

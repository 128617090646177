.DetailsModal {
  width: max-content;
  min-width: 60rem;
  max-width: 90vw;

  &__TableRow {
    align-items: flex-start;
    height: auto;
    padding: 1.5rem 0;
  }

  &__TableCell {
    padding: 0;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }
}

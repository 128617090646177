@import '@matillion/component-library/dist/assets/styles/colours';

.Form {
  padding-top: 1.6rem;

  &__Field {
    width: 100%;
    max-width: 624px;
    margin: 4rem auto auto;

    li {
      padding-left: 40px;
    }

    [class*='AutoComplete__Item--Disabled'] {
      padding-left: 20px;
      color: $black;
      font-weight: bold;
    }
  }

  &__SupportingText {
    min-height: 35px;
    padding-top: 15px;

    &--AlignCenter {
      text-align: center;
    }
  }
}

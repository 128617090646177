@import '@matillion/component-library/dist/assets/styles/colours';

.AppLayout {
  &__Page {
    width: 100%;
    // should be 120px but sections on pages add the extra padding of 8px or 16px based on screen size
    padding-bottom: 104px;
    background-color: $white;

    @media only screen and (max-width: 1023px) {
      padding-bottom: 112px;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.CircledImageContent {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;

  &__Circle {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  &__TextContainer {
    width: calc(100% - 64px);
    overflow: hidden;
    text-align: left;
  }

  &__Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--highlight {
      color: $hurricane;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.CircledImage {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 24px;
  background-color: $misty;
  color: $hurricane;

  &__Tick {
    position: absolute;
    right: -4px;
    bottom: -4px;
  }

  &__ImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';

.Breadcrumbs {
  margin-bottom: 3.2rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    position: relative;

    &:not(:last-child) {
      margin-right: 0.8rem;
      padding-right: 1.3rem;

      &::after {
        content: '/';
        position: absolute;
        top: 0;
        right: 0;
        width: 5px;
        height: 100%;
        line-height: 18px;
      }
    }
  }

  &__link {
    display: inline-block;
    color: $plasma-green;
  }
}

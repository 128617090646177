@import '@matillion/component-library/dist/assets/styles/colours';

.HostTypeSelection {
  &__Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 66px;

    > * {
      margin: 1rem;
    }
  }

  &__Link {
    display: inline-flex;
    align-items: center;

    svg {
      margin-bottom: 3px;
      margin-left: 3px;
      fill: currentColor;
    }
  }
}

@mixin text-ellipsis($line-clamp: 2) {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

@mixin focus-outline-hidden {
  &:focus:not(:focus-visible) {
    &::after {
      content: none;
    }
  }
}

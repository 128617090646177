.AccessListing {
  &__ErrorInfo {
    margin-top: 2.4rem;
  }

  &__Grid {
    width: 100%;
  }

  &__GridCell {
    flex: 1 0;

    &--action {
      flex: 0;
      justify-content: flex-end;
      width: 5rem;
      min-width: 5rem;
    }
  }

  &__GridRow {
    min-height: 8.8rem;
  }
}

@use '@matillion/component-library';
@import '@matillion/component-library/dist/assets/styles/fonts';
@import '~@matillion/billing-frontend-sdk/dist/main.css';

* {
  margin: 0;
  padding: 0;
}

body {
  @include brand-font;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.Layout-module__Layout__Main {
  width: 100%;
}

.Page-module {
  &__Page {
    width: 100%;

    &__Contents {
      width: 100%;
    }
  }
}

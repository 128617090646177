@import '@matillion/component-library/dist/assets/styles/colours';

.WarningIcon {
  width: 24px;
  height: 24px;
  color: $white;

  &__Red {
    circle {
      fill: $fire-red;
    }
  }
}

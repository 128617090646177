@import 'src/styles/mixins';

.CloudProviderCredentialsListing {
  &__Grid {
    width: 100%;
  }

  &__GridCell {
    &--actions {
      flex-grow: 0;
      justify-content: flex-end;
    }

    &--ellipsis-1 {
      @include text-ellipsis(1);
    }
  }
}

/* stylelint-disable */
@import '@matillion/component-library/dist/assets/styles/colours';

.ProjectDetail {
  width: 100%;
  margin-top: 3.2rem;

  &__Header {
    max-width: 800px;
  }

  &__Description {
    margin: 2.4rem auto auto;
  }

  &__TabContainer {
    & > div {
      width: 100%;
      overflow-x: auto;
    }
  }

  &__TabList {
    border-bottom-color: transparent;
    button {
      min-width: auto;
      height: auto;
      padding: 1rem 3.2rem;
      flex-shrink: 0;

      &[aria-selected='false'] {
        border-bottom: 2px solid #ddd;
      }
    }
  }

  &__ContentSelector {
    width: 100%;
    margin-top: 5.6rem;
  }

  &__OutletContainer {
    width: 100%;
    margin-top: 4.8rem;
    padding: 0;
  }

  &__LoadingTitle {
    margin-bottom: 2.4rem;
  }

  &__LoadingDescription {
    margin-top: 0.8rem;
  }
  &__BadgeLoader {
    width: 3em;
  }
}

.CreateBranch {
  &__Field {
    &--normal {
      margin-bottom: 3rem;
    }

    &--spaced {
      margin-bottom: 5.6rem;
    }
  }
}

@import '@matillion/component-library/dist/assets/styles/colours';

.InfrastructureSelection {
  &__Container {
    label > span {
      margin-bottom: 2rem;
    }
  }

  &__RadioSupportText {
    padding-left: 3rem;
  }

  &__RadioHyperlink {
    display: block;
  }
}

@import '@matillion/component-library/dist/assets/styles/spacing';

.WarehouseProvisioningSelection {
  &__Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;

    @include respond-to('large') {
      justify-content: center;
    }

    > * {
      margin: 1rem;
    }
  }

  &__ButtonContainer {
    max-width: calc(100% - 20px);

    @include respond-to('large') {
      max-width: 1060px;
    }
  }
}

.HelpText {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: italic;

  &__Icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

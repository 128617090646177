.Header {
  &__ActionLeft {
    // magic number - align with first line of title at the baseline
    padding-top: 2.4rem;
  }

  &__ActionRight {
    display: flex;
    flex-direction: row-reverse;
    // magic number - align with first line of title at the baseline
    padding-top: 0.6rem;
    text-align: right;
  }

  &__TitleSlot {
    text-align: left;

    &--align-center {
      text-align: center;
    }
  }

  &__CTAWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__Title {
    text-align: left;

    &--align-center {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }

    &--bottom-space {
      margin-bottom: 16px;
    }
  }
}

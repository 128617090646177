.SecretDefinitionFormContent {
  width: 100%;
  max-width: 60rem;
  margin: 6rem auto;

  &__Field {
    &--normal {
      margin-bottom: 3rem;
    }

    &--spaced {
      margin-bottom: 5.6rem;
    }
  }

  &__SubmitBtn {
    margin-left: auto;

    span {
      font-size: 1.4rem;
    }
  }
}
